.panel-list {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));

    .panel + .panel {
        margin-block-start: 0;
    }
}

.panel-list__panel {
    display: block;
}

.panel-list__link {
    flex: 1 1 auto;
}

.scope-properties {
    display: grid;

}

.properties-table {
    width: 100%;

    th {
        font-weight: normal;
        text-align: start;
    }

    .button-set {
        flex-wrap: nowrap;
    }
}

live-preview-root {
    position: absolute;
}
